import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { 
  Container, TextField, Typography, Button, CircularProgress, 
  Input, Table, TableBody, TableCell, TableContainer, 
  TableHead, TableRow, Paper, IconButton 
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete'; // ไอคอนปุ่มลบ
import { getProductById, addProduct, updateProduct, deleteProduct } from '../../controllers/productController';
import { uploadFile } from '../../controllers/FileController'; 

const ProductDetailPage = () => {
  const { productId } = useParams();
  const navigate = useNavigate();

  const [product, setProduct] = useState({
    productName: '',
    description: '',
    imageUrl: '',
    price: 0,
    discount: 0,
    stock: 0, // สต็อกจะถูกอัปเดตอัตโนมัติตามจำนวน CodeCard
  });

  const [codeCards, setCodeCards] = useState([{ code1: '', code2: '', isUsed: false }]);
  const [imageFile, setImageFile] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isEditMode, setIsEditMode] = useState(!!productId);

  useEffect(() => {
    if (isEditMode) {
      const fetchProduct = async () => {
        try {
          const data = await getProductById(productId);
          setProduct(data.product);
          setCodeCards(data.codeCards);
        } catch (err) {
          setError('Failed to load product details.');
        } finally {
          setLoading(false);
        }
      };
      fetchProduct();
    } else {
      setLoading(false);
    }
  }, [productId]);

  useEffect(() => {
    setProduct((prevProduct) => ({
      ...prevProduct,
      stock: codeCards?.filter(x => x.isUsed === false).length,
    }));
  }, [codeCards]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProduct((prevProduct) => ({ ...prevProduct, [name]: value }));
  };

  const handleCodeCardChange = (index, field, value) => {
    const updatedCards = [...codeCards];
    updatedCards[index][field] = value;
    setCodeCards(updatedCards);
  };

  const addCodeCard = () => {
    setCodeCards([...codeCards, { code1: '', code2: '', isUsed: false }]);
  };

  const deleteCodeCard = (index) => {
    const updatedCards = codeCards.filter((_, i) => i !== index);
    setCodeCards(updatedCards);
  };

  const handleFileChange = (e) => {
    setImageFile(e.target.files[0]);
  };

  const handleSave = async () => {
    try {
      let imageUrl = product.imageUrl;
      if (imageFile) {
        imageUrl = await uploadFile(imageFile);
      }

      const updatedProduct = { ...product, imageUrl };
      const payload = { product: updatedProduct, codeCards };

      if (isEditMode) {
        await updateProduct(productId, payload);
      } else {
        await addProduct(payload);
      }

      navigate('/admin/products');
    } catch (err) {
      setError('Failed to save product.');
    }
  };

  const handleDelete = async () => {
    try {
      await deleteProduct(productId);
      navigate('/admin/products');
    } catch (err) {
      setError('Failed to delete product.');
    }
  };

  if (loading) return <Container><CircularProgress /></Container>;
  if (error) return <Container><Typography color="error">{error}</Typography></Container>;

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        {isEditMode ? 'แก้ไขสินค้า' : 'เพิ่มสินค้าใหม่'}
      </Typography>

      <TextField
        label="ชื่อสินค้า"
        name="productName"
        value={product.productName}
        onChange={handleInputChange}
        fullWidth
        margin="normal"
      />
      <TextField
        label="รายละเอียด"
        name="description"
        value={product.description}
        onChange={handleInputChange}
        fullWidth
        margin="normal"
        multiline
        rows={4}
      />
      <TextField
        label="ราคา"
        name="price"
        type="number"
        value={product.price}
        onChange={handleInputChange}
        fullWidth
        margin="normal"
      />
      <TextField
        label="ราคาลด"
        name="discount"
        type="number"
        value={product.discount}
        onChange={handleInputChange}
        fullWidth
        margin="normal"
      />
      <TextField
        label="สต็อก"
        name="stock"
        type="number"
        value={product.stock}
        fullWidth
        margin="normal"
        disabled
      />

      <TableContainer component={Paper} style={{ marginTop: '20px' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center">ลำดับ</TableCell>
              <TableCell align="center">รหัส 1</TableCell>
              <TableCell align="center">รหัส 2</TableCell>
              <TableCell align="center">ลบ</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {codeCards.map((card, index) => (
              <TableRow key={index}>
                <TableCell align="center">{index + 1}</TableCell>
                <TableCell align="center">
                  <TextField
                    value={card.code1}
                    onChange={(e) => handleCodeCardChange(index, 'code1', e.target.value)}
                    margin="normal"
                    disabled={card.isUsed} // ปิดการแก้ไขถ้าใช้ไปแล้ว
                    style={{ backgroundColor: card.isUsed ? '#e0e0e0' : 'inherit' }} // สีเทาถ้าใช้แล้ว
                  />
                </TableCell>
                <TableCell align="center">
                  <TextField
                    value={card.code2}
                    onChange={(e) => handleCodeCardChange(index, 'code2', e.target.value)}
                    margin="normal"
                    disabled={card.isUsed}
                    style={{ backgroundColor: card.isUsed ? '#e0e0e0' : 'inherit' }}
                  />
                </TableCell>
                <TableCell align="center">
                  {!card.isUsed && ( // ซ่อนปุ่มลบถ้าใช้แล้ว
                    <IconButton onClick={() => deleteCodeCard(index)} color="secondary">
                      <DeleteIcon />
                    </IconButton>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Button onClick={addCodeCard} variant="contained" color="primary" style={{ marginTop: '10px' }}>
          เพิ่ม CodeCard
        </Button>
      </TableContainer>

      <Input type="file" onChange={handleFileChange} fullWidth style={{ marginTop: '20px' }} />

      <div style={{ marginTop: '20px' }}>
        <Button variant="contained" color="primary" onClick={handleSave} style={{ marginRight: '10px' }}>
          {isEditMode ? 'บันทึก' : 'เพิ่มสินค้า'}
        </Button>

        {isEditMode && (
          <Button variant="contained" color="secondary" onClick={handleDelete}>
            ลบสินค้า
          </Button>
        )}
      </div>
    </Container>
  );
};

export default ProductDetailPage;
